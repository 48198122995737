import { environment } from '../../../environments/environment';
import { MenuBlock } from './menu.model';

export const MENU_ITEMS: MenuBlock[] = [
  {
    modules: [
      {
        url: environment.modules.dashboard,
        name: 'menu.dashboard',
        icon: 'one-icon-house-v2',
        active: true,
        isNew: true
      }
    ],
    active: true
  },
  {
    product: 'menu.analyze',
    modules: [
      {
        url: environment.modules.analytics,
        name: 'menu.analytics',
        icon: 'one-icon-outline-activity',
        active: true
      },
      {
        url: environment.modules.shppperInsights,
        name: 'menu.shppperInsights',
        icon: 'one-icon-outline-happy-face',
        active: true
      },
      {
        url: environment.modules.basicAnalysis,
        name: 'Basic Analysis',
        icon: 'one-icon-graph',
        active: true,
        isNew: true
      },
      {
        url: environment.modules.campaignReports,
        name: 'Campaign Reports',
        icon: 'one-icon-outline-document-check',
        active: true,
        isNew: true
      }
    ],
    active: true
  },
  {
    product: 'menu.promote',
    modules: [
      {
        url: environment.modules.create,
        name: 'Create',
        icon: 'one-icon-tools',
        active: true
      },
      {
        url: environment.modules.reportsMef,
        name: 'Reports',
        icon: 'one-icon-outline-document-check',
        active: true,
        isNew: true
      }
    ],
    active: true
  },
  {
    product: 'menu.manage',
    modules: [
      {
        url: `${environment.modules.catalog}`,
        name: 'menu.catalog',
        icon: 'one-icon-groceries-v2',
        active: true
      }
    ],
    active: true
  },
  {
    product: 'menu.internal',
    modules: [
      {
        url: `${environment.modules.internalTools}/${environment.modules.settings}`,
        name: 'menu.settings',
        icon: 'one-icon-settings-v2',
        children: [
          {
            name: 'Amplify',
            url: environment.modules.amplify,
            active: true
          },
          {
            name: 'Request Hub',
            url: environment.modules.requestHubMfe,
            active: true
          }
        ],
        active: true
      },
      {
        url: `${environment.modules.internalTools}/${environment.modules.samplingCore}`,
        name: 'menu.samplingOps',
        icon: 'one-icon-box-v2',
        children: [
          {
            name: 'Management',
            url: 'management',
            active: true
          },
          {
            name: 'Warehouse',
            url: 'warehouses',
            active: true
          },
          {
            name: 'Settings',
            url: 'settings',
            active: true
          },
          {
            name: 'Pricing',
            url: 'pricing',
            active: true
          }
        ],
        active: true
      },
      {
        url: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
        name: 'Trade',
        icon: 'one-icon-outline-increase',
        active: true
      },
      {
        url: `${environment.modules.internalTools}/${environment.modules.masterData}`,
        name: 'menu.masterData',
        icon: 'one-icon-thunder-v2',
        children: [
          {
            name: 'Entity Management',
            url: 'entity-management',
            active: true
          },
          {
            name: 'Approvals',
            url: 'approvals',
            active: true
          },
          {
            name: 'Bulk Loads',
            url: 'bulk-loads',
            active: true
          },
          {
            name: 'menu.mdCatalogManager',
            url: 'catalog-management',
            active: true
          }
        ],
        active: true
      }
    ],
    active: true
  }
];

export const MENU_TIERS = {
  free: {
    title: 'Free',
    icon: 'one-icon-check',
    color: '#dffadb'
  },

  boost: {
    title: 'Boost',
    icon: 'one-icon-bookmark',
    color: '#dafffc'
  },

  pro: {
    title: 'Pro',
    icon: 'one-icon-oval-bar-chart',
    color: '#d9d5ff'
  },

  enterprise: {
    title: 'Enterprise',
    icon: 'one-icon-offline-bolt',
    color: 'rgba(69, 137, 255, 0.23)'
  }
};
