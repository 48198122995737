export enum StatesPaymentMethods {
  EXPIRED = 'EXPIRED',
  UNENROLL_IN_PROCESS = 'UNENROLL_IN_PROCESS',
  ENROLL_IN_PROCESS = 'ENROLL_IN_PROCESS',
  ENROLLED = 'ENROLLED',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  DECLINED = 'DECLINED',
  CREATED = 'CREATED',
  READY_TO_ENROLL = 'READY_TO_ENROLL'
}
