<div mat-dialog-title>
  <div class="title-container">
    <div class="title-modal d-flex f-align-center"></div>
  </div>

  <div mat-dialog-content>
    <div class="content-container">
      <img *ngIf="checkStates.includes(data.state)" [src]="srcCheck" alt="check" />
      <img *ngIf="failedStates.includes(data.state)" [src]="srcFailed" alt="failed" />
      <img *ngIf="warningStates.includes(data.state)" [src]="srcWarning" alt="warning" />
      <span *ngIf="checkStates.includes(data.state)" class="description">
        Your card has been successfully registered.
      </span>
      <span *ngIf="failedStates.includes(data.state)" class="description">
        We couldn’t register your card. Please try again later or use a different card.
      </span>
      <span *ngIf="warningStates.includes(data.state)" class="description">
        Your card is being validated. It will be registered shortly.
      </span>
    </div>
  </div>

  <div mat-dialog-actions>
    <button class="rp-button rp-filled rp-primary" (click)="close()">Got it</button>
  </div>
</div>
