import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { UserResponseAuth, Module, LoginResponseAuth, Action } from '../../models/user/user.model';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as userActions from '../../store/actions/user.action';
import { UserState } from '../../store/states/user.state';
import { UtilService } from '../util/util.service';
import { STORAGE_KEY } from '../../definitions/app.constants';
import { UserType } from '../../permissions/definitions/permissions.enums';
import { StorageService } from '../storage/storage.service';
import { PATHS } from '../../core/landing/definitions/landing.constant';
import { Country } from '../../models/shared/shared.model';
import { MENU_ITEMS } from '../../core/menu/menu.constant';
import { MenuBlock } from '../../core/menu/menu.model';
import { EventsService } from '../events/events.service';
import { EncryptService } from '../encrypt/encrypt.service';

@Injectable()
export class AuthService {
  readonly DEFAULT_PATH = `/${environment.modules.dashboard}`;
  readonly MY_ACCOUNT_PATH = `/${environment.modules.account}`;

  constructor(
    private readonly _http: HttpClient,
    private readonly _storageService: StorageService,
    private readonly _router: Router,
    private readonly _store: Store<UserState>,
    private readonly _utilService: UtilService,
    private readonly _eventsService: EventsService,
    private readonly _encryptService: EncryptService
  ) { }

  login(email: string, password: string): Observable<LoginResponseAuth> {
    return this._http.post<LoginResponseAuth>(`${this._getUrl()}/login`, { email, password });
  }

  successLogin(data: UserResponseAuth): void {
    this._storageService.saveDataOnStorage(data);
    this._store.dispatch(userActions.set({ payload: data.actions as UserState }));

    if (data.countries.some((country: Country) => country.platform_outage)) {
      this._router.navigateByUrl(`/${PATHS.platformOutage}`);
    } else {
      const hasAlert =
        (data.countries_alert &&
          data.countries.find((country) => data.countries_alert.countries.includes(country.code)));

      const alert = hasAlert ? data.countries_alert : null;
      this.navigateToDashboard(data.actions, { state: { alert } });
    }
  }

  logout(): Observable<unknown> {
    return this._http.post(`${this._getUrl()}/logout`, null, this._getHeaders());
  }

  reset(password: string, token: string): Observable<{ message: string }> {
    return this._http.post<{ message: string }>(`${this._getUrl()}/password/reset/${token}`, { password });
  }

  forgot(email: string): Observable<{ message: string }> {
    return this._http.post<{ message: string }>(`${this._getUrl()}/password/forgot`, { email });
  }

  getGoogleAuthUrl(): Observable<{ data: string }> {
    return this._http.get<{ data: string }>(`${this._getUrl()}/google/url`);
  }

  exchangeGoogleCode(state: string, code: string): Observable<{ message: string; data: UserResponseAuth }> {
    return this._http.post<{ message: string; data: UserResponseAuth }>(`${this._getUrl()}/google/callback`, {
      state,
      code
    });
  }

  navigateToDashboard({ user_type, modules }: { user_type: string; modules: Module[] }, params?: unknown): void {
    const isAdmin = user_type === UserType.admin;
    // Ordenar los módulos asignados según el orden definido en MENU_ITEMS
    const sortedModules = this.sortAssignedModules(isAdmin ? [] : modules);
    const route = isAdmin ? this.DEFAULT_PATH : this.firstModuleAvailable(sortedModules, sortedModules[0]);
    this.trackAmplitudeDefaultNavigate(route);
    this._router.navigate([route], params);
  }

  trackAmplitudeDefaultNavigate(route: string, isProd = environment.production): void {
    const data = {
      os: this._eventsService.getOS(),
      isMobile: Boolean(this._eventsService.isMobile()),
      id: localStorage.getItem(STORAGE_KEY.id),
      email: localStorage.getItem(STORAGE_KEY.email),
      country: this._encryptService.decryptText(localStorage.getItem(STORAGE_KEY.country), STORAGE_KEY.country),
      actions: this._encryptService.decryptObject(localStorage.getItem(STORAGE_KEY.actions), STORAGE_KEY.actions),
      route
    };

    const event = {
      COUNTRY: data.country,
      PAGE_NAME: data.route,
      SOURCE: '/login',
      USER_EMAIL: data.email,
      TIER: data.actions.tier_name,
      TYPE: data.email.includes('@rappi') ? 'internal' : 'external',
      ACCOUNT_ID: data.id,
      TITLE: data.actions.account_name,
      IS_MOBILE: data.isMobile,
      DEVICE_TYPE: data.os,
      PAGE_DEFAULT: true,
    };

    if (isProd) {
      this._eventsService.triggerAmplitudeEvent({
        EVENT_NAME: 'VIEW_PAGE_BBR', EVENT_PROPS: event
      });
    }
  }

  firstModuleAvailable(assignedModules: Module[], moduleFound: Module): string {
    // Verificar si el módulo actual ya está en la lista de módulos asignados
    const isDirectMatch = assignedModules.some(({ slug }) => slug === moduleFound.slug);

    // Si el módulo actual está directamente en la lista, detener la recursión
    if (isDirectMatch) {
      return moduleFound.slug;
    }

    // Buscar un slug más específico que contenga el slug actual como prefijo
    const slugFound = assignedModules.find(({ slug }) => slug.startsWith(`${moduleFound.slug}/`));

    // Si no se encuentra un slug más específico, retornar el módulo actual
    if (!slugFound) {
      return moduleFound.slug;
    }

    // Continuar con el siguiente nivel de la jerarquía
    return this.firstModuleAvailable(assignedModules, slugFound);
  }

  sortAssignedModules(assignedModules: Module[]): Module[] {
    // Extraer todas las URLs de MENU_ITEMS usando recursividad
    const menuUrls: string[] = this.extractUrlsFromMenuItems(MENU_ITEMS);

    // Filtrar assignedModules para excluir los que no están en menuUrls
    const filteredModules = assignedModules.filter(module => menuUrls.includes(module.slug));

    // Crear una copia de filteredModules antes de ordenarlo
    const filteredModulesCopy = [...filteredModules];

    // Ordenar filteredModules en base a la posición de sus slugs en menuUrls
    return filteredModulesCopy.sort((a, b) => {
      const indexA = menuUrls.indexOf(a.slug);
      const indexB = menuUrls.indexOf(b.slug);
      return indexA - indexB;
    });
  }

  extractUrlsFromMenuItems(menuItems: MenuBlock[]): string[] {
    const urls: string[] = [];

    const extractUrls = (modules: { url: string; children?: { url: string }[] }[]) => {
      modules.forEach(module => {
        urls.push(module.url);
        if (module.children) {
          extractUrls(module.children);
        }
      });
    };

    menuItems.forEach((menuBlock: { modules }) => {
      extractUrls(menuBlock.modules);
    });

    return urls;
  }

  private _getUrl(): string {
    return this._utilService.concatUrlPath(environment.urlAuth, environment.production ? 'CO' : 'DEV');
  }

  private _getHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem(STORAGE_KEY.token)}`
      })
    };
  }
}
