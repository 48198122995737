import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckStates, FailedStates, WarningStates } from '../../definitions/payment-methods.constants';
import { StatesPaymentMethods } from '../../definitions/payment-methods.enum';

@Component({
  selector: 'app-create-status-card-dialog',
  templateUrl: './create-status-card-dialog.component.html',
  styleUrls: ['./create-status-card-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateStatusCardDialogComponent {
  srcCheck = 'assets/images/check-card.png';
  srcFailed = 'assets/images/failed.png';
  srcWarning = 'assets/images/warning.svg';

  checkStates = CheckStates;
  failedStates = FailedStates;
  warningStates = WarningStates;

  constructor(
    public _dialogRef: MatDialogRef<CreateStatusCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { state: StatesPaymentMethods }
  ) {}

  close() {
    this._dialogRef.close();
  }
}
