<div class="account-wrapper">
  <one-card class="no-padding">
    <div class="content-info">
      <div class="content-avatar">
        <img [src]="srcImgAvatar" alt="avatar" />
      </div>
      <div class="content-info-account">
        <span class="name-user">{{ fullName | titlecase }}</span>
        <span class="user-type">{{ userType$ | async | titlecase }}</span>
      </div>
    </div>
    <div class="content-options">
      <a routerLinkActive="active-option" [routerLink]="['./personal-information']">
        <em class="one-icon-user-box"></em>
        <span>Personal Information</span>
      </a>
      <a routerLinkActive="active-option" [routerLink]="['./change-password']">
        <em class="one-icon-key"></em>
        <span> Change Password </span>
      </a>
      <a *ngIf="isFeatureFlag" routerLinkActive="active-option" [routerLink]="['./payment-methods']">
        <em class="one-icon-credit-card-v3"></em>
        <span>Payment methods</span>
      </a>
    </div>
  </one-card>
  <div class="layout"><router-outlet></router-outlet></div>
</div>
