import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';

import { PermissionGuard } from './services/guards/permissions/permissions.guard';
import { environment } from '../environments/environment';

import { BackOfficeComponent } from './core';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./core/landing/landing.module').then((m) => m.LandingV2Module)
  },
  {
    path: environment.modules.session,
    loadChildren: () => import('./session/session.module').then((m) => m.SessionModule)
  },
  {
    path: '',
    component: BackOfficeComponent,
    canActivateChild: [PermissionGuard],
    children: [
      {
        path: environment.modules.controlPanel,
        loadChildren: () => import('./control-panel/control-panel.module').then((m) => m.ControlPanelModule),
        data: { module: environment.modules.controlPanel }
      },
      {
        path: environment.modules.account,
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule)
      },
      {
        path: environment.modules.permissions,
        loadChildren: () => import('./permissions/permissions.module').then((m) => m.PermissionsModule),
        data: { module: environment.modules.permissions }
      },
      {
        path: 'analyze',
        children:[
          { path: '**', redirectTo: `/${environment.modules.analytics}/${environment.modules.analyzePerformance}`, pathMatch: 'full' }
        ]
      },
      {
        path: environment.modules.dashboard,
        loadChildren: () => import('./dashboard-mfe/dashboard-mfe.module').then((m) => m.DashboardMfeModule),
        data: { module: environment.modules.dashboard }
      },
      {
        path: environment.modules.analytics,
        loadChildren: () => import('./analyze-mfe/analyze-mfe.module').then((m) => m.AnalyzeMfeModule),
        data: { parentModule: environment.modules.analytics, module: environment.modules.analytics }
      },
      {
        path: environment.modules.basicAnalysis,
        loadChildren: () => import('./basic-analytics-mfe/basic-analytics-mfe.module').then((m) => m.BasicAnalyticsMfeModule),
        data: { module: environment.modules.basicAnalysis }
      },
      {
        path: environment.modules.campaignReports,
        loadChildren: () => import('./reports-mfe/campaign-reports-mfe/campaign-reports-mfe.module').then((m) => m.CampaignReportsMfeModule),
        data: { module: environment.modules.campaignReports }
      },
      {
        path: 'sense',
        children:[
          { path: '**', redirectTo: `/${environment.modules.shppperInsights}/${environment.modules.senseBehaviour}`, pathMatch: 'full' }
        ]
      },
      {
        path: environment.modules.shppperInsights,
        loadChildren: () => import('./sense-mfe/sense-mfe.module').then((m) => m.SenseMfeModule),
        data: { parentModule: environment.modules.shppperInsights, module: environment.modules.shppperInsights }
      },
      {
        path: environment.modules.create,
        loadChildren: () => import('./create-core/create-core.module').then((m) => m.CreateCoreModule),
        data: { module: environment.modules.create }
      },
      {
        path: `${environment.modules.reportsMef}/${environment.modules.reportsSmartAds}`,
        children:[
          { path: '**', redirectTo: `/${environment.modules.reportsMef}/${environment.modules.tradeCore}`, pathMatch: 'full' }
        ]
      },
      {
        path: environment.modules.reportsMef,
        loadChildren: () => import('./reports-mfe/reports-mfe.module').then((m) => m.ReportsMfeModule),
        data: { parentModule: environment.modules.reportsMef, module: environment.modules.reportsMef }
      },
      {
        path: environment.modules.catalog,
        loadChildren: () => import('./catalog-mfe/catalog-mfe.module').then((m) => m.CatalogMfeModule),
        data: { module: environment.modules.catalog }
      },
      {
        path: environment.modules.internalTools,
        children: [
          {
            path: environment.modules.settings,
            loadChildren: () => import('./settings-mfe/settings-mfe.module').then((m) => m.SettingsMfeModule),
            data: {
              module: `${environment.modules.internalTools}/${environment.modules.settings}`,
              hideBreadcrumbs: true
            }
          },
          {
            path: environment.modules.masterData,
            loadChildren: () => import('./master-data-mfe/master-data-mfe.module').then((m) => m.MasterDataMfeModule),
            data: {
              module: `${environment.modules.internalTools}/${environment.modules.masterData}`,
              breadcrumbs: environment.breadcrumbs.masterData.value
            }
          },
          {
            path: environment.modules.samplingCore,
            loadChildren: () => import('./sampling-core/sampling-core.module').then((m) => m.SamplingCoreModule),
            data: {
              module: `${environment.modules.internalTools}/${environment.modules.samplingCore}`,
              breadcrumbs: 'Sampling Ops'
            }
          },
          {
            path: environment.modules.tradeCore,
            loadChildren: () => import('./trade-core/trade-core.module').then((m) => m.TradeCoreModule),
            data: {
              module: `${environment.modules.internalTools}/${environment.modules.tradeCore}`,
              hideBreadcrumbs: true
            }
          }
        ],
        data: {
          module: environment.modules.internalTools
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: environment.route_hash, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
