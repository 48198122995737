<div mat-dialog-title>
  <div class="title-container">
    <div class="title-modal d-flex f-align-center">
      <h1 class="title-modal">Delete card</h1>
    </div>
    <span class="icon one-icon-close-v2" (click)="close()"></span>
  </div>

  <div mat-dialog-content>
    <div class="content-container">
      <img [src]="srcImgPreview" alt="preview" />
      <span class="description">Are you sure you want to delete this card?</span>
      <span class="card-text"
        >{{ transformInfo(data.card.brand) }} {{ transformInfo(data.card.type) }} *{{ data.card.lfd }}</span
      >
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button class="rp-button rp-outlined rp-primary" (click)="close()">Cancel</button>
  <button class="rp-button rp-filled rp-primary" (click)="delete()">Yes, delete it</button>
</div>
