import { CountryState } from './../../../store/states/country.state';
import { StoreName } from './../../../store/definitions/store.constants';
import { AppState } from './../../../store/states/app.state';
import { EncryptService } from './../../../services/encrypt/encrypt.service';
import { User } from './../../../models/user/user.model';
import { UsersService } from './../../../services/users/users.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, NgZone } from '@angular/core';
import { MessagesStates, DisabledStatus } from '../../definitions/payment-methods.constants';
import { CardData, PaymentMethods, ResponsePaymentMethods } from '../../definitions/payment-methods.interface';
import { MatDialog } from '@angular/material/dialog';
import { DeleteCardDialogComponent } from '../delete-card-dialog/delete-card-dialog.component';
import { transformStatePayment, transformText } from '../../utils/transform-text';
import { CreateStatusCardDialogComponent } from '../create-status-card-dialog/create-status-card-dialog.component';
import { EventsService } from './../../../services/events/events.service';
import { StatesPaymentMethods } from '../../definitions/payment-methods.enum';
import { TriggerAmplitudeEntity } from './../../../services/events/event.interface';
import { PaymentMethodsService } from '../../services/payment-methods.service';
import { catchError, filter, finalize, map, skip, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-var
declare var Yuno;

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentMethodsComponent implements OnInit, OnDestroy {
  destroySubject$: Subject<void> = new Subject();
  srcImgPreview = 'assets/images/preview-credit-card.svg';
  cards: PaymentMethods[] = [];
  disabledStatus = DisabledStatus;
  messagesStates = MessagesStates;
  loading = false;
  yunoInstance;
  checkout: { session: string; public_api_key: string };
  user: User;
  disabledCreate = false;

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _eventsService: EventsService,
    private readonly _service: PaymentMethodsService,
    private readonly _usersService: UsersService,
    private readonly _encrypService: EncryptService,
    private readonly _zone: NgZone,
    private readonly _store: Store<AppState>
  ) {}

  ngOnInit(): void {
    const event = {
      EVENT_NAME: 'VIEW_PAGE_BBR',
      EVENT_PROPS: {
        PAGE_NAME: '/account/payment-methods'
      }
    };

    this.emitEventPayment(event);

    this.getCards();

    this._usersService
      .getProfile()
      .pipe(map(({ data }: { data: User }): User => data))
      .subscribe((user: User) => {
        this.user = user;
      });

    this._store
      .pipe(
        takeUntil(this.destroySubject$),
        select(StoreName.country),
        filter((state: CountryState) => Boolean(state.countrySelected)),
        skip(1)
      )
      .subscribe(() => {
        this.getCards();
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  deleteCard(card: CardData, token: string): void {
    const dialogRef = this._dialog.open(DeleteCardDialogComponent, {
      maxWidth: '80%',
      width: '650px',
      disableClose: true,
      data: { card }
    });

    dialogRef
      .afterClosed()
      .pipe(filter((result: string) => result === 'delete'))
      .subscribe(() => {
        this.loading = true;
        this._service
          .deletePaymentMethod(token)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(() => {
            this.getCards();
          });
      });
  }

  transformType(text: string): string {
    return transformText(text);
  }

  addCard(): void {
    this.disabledCreate = true;

    const event = {
      EVENT_NAME: 'SELECT_BBR_COMPONENT',
      EVENT_PROPS: {
        COMPONENT_NAME: 'Add new card'
      }
    };

    this.emitEventPayment(event);

    this.onYunoInit();
  }

  onYunoInit(): void {
    const country = this._encrypService.decryptText(localStorage.getItem('country'), 'country');

    if (!this.yunoInstance) {
      this._service
        .paymentSessions(this.user.first_name, this.user.last_name, this.user.email)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((response) => {
          this.checkout = response;
          const countryCode = country === 'DEV' ? 'CO' : country;
          this.yunoInstance = Yuno.initialize(this.checkout.public_api_key);
          this.yunoInstance.mountEnrollmentLite({
            countryCode,
            customerSession: this.checkout.session,
            language: 'en',
            showLoading: true,
            elementSelector: 'sdk-root',
            card: {
              type: 'extends',
              documentEnable: false
            },
            yunoEnrollmentStatus: ({ status, vaultedToken }) => {
              this._zone.run(() => {
                if (status) {
                  this.createPaymentMethod(this.checkout.session, vaultedToken, status);
                  this.openModal(status);
                }
              });
            },
            yunoError: () => {
              this.yunoInstance = null;
              this.disabledCreate = false;
            }
          });
        });
    }
  }

  emitEventPayment(event: TriggerAmplitudeEntity): void {
    this._eventsService.triggerAmplitudeEvent(event);
  }

  getCards(): void {
    this.loading = true;
    this._service
      .getPaymentCards()
      .pipe(
        takeUntil(this.destroySubject$),
        finalize(() => (this.loading = false))
      )
      .subscribe((response: ResponsePaymentMethods) => {
        this.cards = response.payment_methods;
      });
  }

  openModal(status: string): void {
    const event = {
      EVENT_NAME: 'SHOW_MODAL',
      EVENT_PROPS: {
        MODAL_NAME: transformStatePayment(status as StatesPaymentMethods)
      }
    };

    const dialogRef = this._dialog.open(CreateStatusCardDialogComponent, {
      maxWidth: '80%',
      width: '750px',
      disableClose: true,
      data: { state: status }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.emitEventPayment(event);
      this.yunoInstance = null;
      this.disabledCreate = false;
    });
  }

  createPaymentMethod(session: string, token: string, status: string): void {
    this.loading = true;
    this._service
      .createPaymentMethod(session, token, status)
      .pipe(
        takeUntil(this.destroySubject$),
        finalize(() => (this.loading = false)),
        catchError(() => EMPTY)
      )
      .subscribe(() => {
        this.getCards();
      });
  }
}
