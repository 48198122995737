import { StatesPaymentMethods } from './payment-methods.enum';

export const MockCards = [
  {
    name: 'AMERICAN_EXPRESS ****0002',
    description: 'AMERICAN_EXPRESS ****0002',
    icon: 'https://icons.prod.y.uno/amex_logosimbolo.png',
    type: 'CARD',
    category: 'CARD',
    country: 'CO',
    status: 'ENROLLED',
    created_at: '2024-12-20T21:15:12.554175Z',
    updated_at: '2024-12-20T21:20:24.334084Z',
    card_data: {
      iin: '37000000',
      lfd: '0002',
      holder_name: 'JOHN DOE',
      expiration_month: 11,
      expiration_year: 28,
      number_length: 15,
      security_code_length: 4,
      brand: 'AMERICAN_EXPRESS',
      issuer_name: 'ACME',
      category: 'CREDIT',
      type: 'CREDIT'
    }
  }
];

export const DisabledStatus = [
  StatesPaymentMethods.UNENROLL_IN_PROCESS,
  StatesPaymentMethods.ENROLL_IN_PROCESS,
  StatesPaymentMethods.CREATED,
  StatesPaymentMethods.READY_TO_ENROLL
];

export const MessagesStates = {
  [StatesPaymentMethods.EXPIRED]: 'Expired card',
  [StatesPaymentMethods.UNENROLL_IN_PROCESS]: 'Delete in process',
  [StatesPaymentMethods.ENROLL_IN_PROCESS]: 'Registration in progress'
};

export const CheckStates = [StatesPaymentMethods.ENROLLED];

export const FailedStates = [
  StatesPaymentMethods.ERROR,
  StatesPaymentMethods.FAILED,
  StatesPaymentMethods.REJECTED,
  StatesPaymentMethods.CANCELED,
  StatesPaymentMethods.DECLINED
];

export const WarningStates = [
  StatesPaymentMethods.CREATED,
  StatesPaymentMethods.READY_TO_ENROLL,
  StatesPaymentMethods.ENROLL_IN_PROCESS
];

export const PermissionBasePath = 'one-frontend';
export const PaymentMethodsF = 'payment-methods';
