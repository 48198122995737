import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CardData } from '../../definitions/payment-methods.interface';
import { transformText } from '../../utils/transform-text';

@Component({
  selector: 'app-delete-card-dialog',
  templateUrl: './delete-card-dialog.component.html',
  styleUrls: ['./delete-card-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteCardDialogComponent {
  srcImgPreview = 'assets/images/delete-card-account.svg';

  constructor(
    public _dialogRef: MatDialogRef<DeleteCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { card: CardData }
  ) {}

  delete() {
    this._dialogRef.close('delete');
  }

  close() {
    this._dialogRef.close();
  }

  transformInfo(text: string): string {
    return transformText(text);
  }
}
