import { CheckStates, FailedStates, WarningStates } from '../definitions/payment-methods.constants';
import { StatesPaymentMethods } from '../definitions/payment-methods.enum';

export const transformText = (text: string): string =>
  text
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const transformStatePayment = (paymentState: StatesPaymentMethods): string => {
  if (CheckStates.includes(paymentState)) {
    return 'Enrollment success';
  } else if (FailedStates.includes(paymentState)) {
    return 'Enrollment failed';
  } else if (WarningStates.includes(paymentState)) {
    return 'Enrollment pending';
  } else {
    return 'Unknown status';
  }
};
