import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardMfeRoutingModule } from './dashboard-mfe-routing.module';
import { DashboardMfeComponent } from './dashboard-mfe.component';
@NgModule({
  declarations: [DashboardMfeComponent],
  imports: [
    CommonModule,
    DashboardMfeRoutingModule
  ]
})
export class DashboardMfeModule { }
