import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputModule } from '@rappi/ui/input';
import { FormErrorsDirectiveModule } from '@rappi/common';
import { CardModule } from '@rappi/ui/card';

import { PersonalInformationComponent } from './components/personal-information/personal-information.component';
import { AccountComponent } from './account.component';
import { InputCellphoneModule } from '../core/landing/components/input-cellphone/input-cellphone.module';

import { UsersService } from '../services/users/users.service';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { DeleteCardDialogComponent } from './components/delete-card-dialog/delete-card-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CreateStatusCardDialogComponent } from './components/create-status-card-dialog/create-status-card-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [
    CommonModule,
    AccountRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    FormErrorsDirectiveModule,
    CardModule,
    InputCellphoneModule,
    MatDialogModule,
    MatProgressBarModule
  ],
  declarations: [
    PersonalInformationComponent,
    AccountComponent,
    ChangePasswordComponent,
    PaymentMethodsComponent,
    DeleteCardDialogComponent,
    CreateStatusCardDialogComponent
  ],
  providers: [UsersService]
})
export class AccountModule {}
