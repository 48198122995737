import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { StoreName } from '../store/definitions/store.constants';
import { UserState } from '../store/states/user.state';
import { AppState } from '../store/states/app.state';
import { STORAGE_KEY } from '../definitions/app.constants';
import { map, takeUntil } from 'rxjs/operators';
import { PaymentMethodsService } from './services/payment-methods.service';
import { Subject } from 'rxjs';
import { PaymentMethodsF, PermissionBasePath } from './definitions/payment-methods.constants';
import { CountryState } from '../store/states/country.state';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountComponent implements OnInit, OnDestroy {
  srcImgAvatar = 'assets/images/profile-picture.png';
  fullName = localStorage.getItem(STORAGE_KEY.fullName);
  userType$ = this._store.pipe(
    select(StoreName.user),
    map(({ user_type }: UserState) => user_type)
  );

  destroySubject$: Subject<void> = new Subject();
  isFeatureFlag = false;

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _service: PaymentMethodsService,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {
    this._store.pipe(select(StoreName.country), takeUntil(this.destroySubject$)).subscribe((state: CountryState) => {
      this.getFeatureFlag(state.countrySelected.toLowerCase());
    });
  }

  getFeatureFlag(country: string): void {
    this._service
      .getConfigurations()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((flag) => {
        this.isFeatureFlag = flag[PermissionBasePath][PaymentMethodsF][country];

        if (!this.isFeatureFlag) {
          this._router.navigate(['/account/personal-information']);
        }
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
