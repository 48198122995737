import { ChangeDetectorRef, Component } from '@angular/core';
import { MicroFrontendWrapperComponent } from '../mfe/models/abstracts';
import { MfeAttribute, MfeEvent, MfeFileSource } from '@rappi/rappi-mfe-tools';
import { MfeSourceLoaderService } from '../mfe/services';
import { EncryptService } from '../services/encrypt/encrypt.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../store/states/app.state';
import { TranslateApplicationService } from '../services/translate/translate-application.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-dashboard-mfe',
  templateUrl: '../mfe/models/abstracts/MicroFrontendWrapper.html',
  styleUrls: ['../mfe/models/abstracts/MicroFrontendWrapper.scss']
})
export class DashboardMfeComponent extends MicroFrontendWrapperComponent {
  protected elName: string;
  protected mfeSources: MfeFileSource[];
  protected mfeAttributes: MfeAttribute[];
  protected mfeEvents: MfeEvent[];

  constructor(
    public readonly sourceLoader: MfeSourceLoaderService,
    public readonly cd: ChangeDetectorRef,
    public readonly encryptService: EncryptService,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly store: Store<AppState>,
    public readonly translateApplicationService: TranslateApplicationService
  ) {
    super(sourceLoader, cd, encryptService, router, activatedRoute, store, translateApplicationService);
    this.elName = 'ads-home-mfe';
    this.url = environment.urlHomeDashboardMfeSourceRoot;
  }
}
