import { Component, OnInit } from '@angular/core';

import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

import { User } from '../../../models/user/user.model';
import { UsersService } from '../../../services/users/users.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';
import { map } from 'rxjs/operators';
import { EventsService } from './../../../services/events/events.service';
import { TriggerAmplitudeEntity } from './../../../services/events/event.interface';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit {
  form: FormGroup = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    company: new FormControl({ value: null, disabled: true }, Validators.required),
    job_title: new FormControl('', Validators.required),
    email: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.required, Validators.email]))
  });

  constructor(
    private readonly _usersService: UsersService,
    private readonly _alertsService: AlertsService,
    private readonly _eventsService: EventsService
  ) {}

  get company(): AbstractControl {
    return this.form.get('company');
  }

  get phone(): AbstractControl {
    return this.form.get('phone');
  }

  ngOnInit() {
    const event = {
      EVENT_NAME: 'VIEW_PAGE_BBR',
      EVENT_PROPS: {
        PAGE_NAME: '/account/personal-information'
      }
    };
    this.emitEvent(event);

    this._usersService
      .getProfile()
      .pipe(map(({ data }: { data: User }): User => data))
      .subscribe(
        (user: User) => {
          this.form.patchValue({ ...user, company: user.actions.company_name });
        },
        ({ error: { message } }: HttpErrorResponse) => {
          this._alertsService.openAlerts(message, AlertsType.error);
        }
      );
  }

  onSubmit(f: FormGroup): void {
    if (f.invalid) {
      f.markAllAsTouched();
      return;
    }

    const event = {
      EVENT_NAME: 'SELECT_BBR_COMPONENT',
      EVENT_PROPS: {
        COMPONENT_NAME: 'Save'
      }
    };

    this.emitEvent(event);

    this._usersService.updateUser(f.getRawValue() as User).subscribe(
      ({ message }: { message: string }) => {
        this._alertsService.openAlerts(message, AlertsType.success);
        this.form.markAsPristine();
      },
      ({ error: { message } }: HttpErrorResponse) => {
        this._alertsService.openAlerts(message, AlertsType.error);
      }
    );
  }

  emitEvent(event: TriggerAmplitudeEntity): void {
    this._eventsService.triggerAmplitudeEvent(event);
  }
}
